<template>
    <div id="box">
        <Header :headerIndex="1"></Header>
        <div class="container">
            <div class="index">
                <div class="part3">
                    <div class="m1340">
                        <div class="title tc">MARKET</div>
                        <div class="introduction tc">Introduction to Mall Exchange. Introduction to Mall Exchange.
                            Introduction to Mall Exchange</div>
                        <v-chart :option="option_column" class="echarts" autoresize></v-chart>
                    </div>
                </div>
                <div class="part4">
                    <div class="m1340">
                        <div class="flex-box flex-col-start buy_box">
                            <div>
                                <div class="fs26 col10 fwb pb30 titles">BUYING M-Coins</div>
                                <div class="flex-box inputs">
                                    <div class="fs20 col10 fwb pr20">RP</div>
                                    <el-input v-model="price" :readonly="true" placeholder="RP Price"
                                        class="golden"></el-input>
                                    <div class="plr10">X</div>
                                    <el-input v-model="num" placeholder="" class="golden"></el-input>
                                </div>
                                <a href="javascript:;" class="btn confirm fs14 tc" @click="confirmbuy">CONFIRM BUY</a>
                                <div class="flex-box flex-center">
                                    <a href="javascript:;" class="btn buy fs14 tc" @click="topPop = true">TOP 100</a>
                                    <a href="javascript:;" class="btn buy fs14 tc" @click="mybuyPop = true">MY BUYING</a>
                                    <!-- <a href="javascript:;" class="btn buy fs14 tc" @click="topPop=true">EXPIRING RP</a> -->
                                    <!-- <router-link class="btn buy fs14 tc" to="/rp_account?type=2">EXPIRING RP</router-link> -->
                                </div>
                            </div>
                            <div class="flex-grow-1 tc">
                                <div class="fs26 col10 fwb pb30 titles">M-Coins</div>
                                <div class="fs16 coin flex-box flex-center">
                                    <span>1</span>
                                    <img src="../assets/static/icon/coins.png" class="coins mlr20" alt="" />
                                    <span>at</span>
                                </div>
                                <div class="points">{{ configs.minprice }}</div>
                            </div>
                            <div>
                                <div class="fs30 col10 fwb pb30 titles tr">SELLING M-Coins</div>
                                <div class="flex-box flex-end inputs">
                                    <div class="fs20 col10 fwb pr20">RP</div>
                                    <el-input v-model="price2"
                                        :readonly="configs.price < configs.circulation_periodprice ? true : false"
                                        placeholder="RP Price" class="golden"></el-input>
                                    <div class="plr10">X</div>
                                    <el-input v-model="num2" placeholder="" class="golden"></el-input>
                                </div>
                                <a href="javascript:;" class="btn confirm fs14 tc" @click="confirmsell">CONFIRM SELL</a>
                                <div class="flex-box flex-center">
                                    <a href="javascript:;" class="btn buy fs14 tc" @click="topPop = true">TOP 100</a>
                                    <a href="javascript:;" class="btn buy fs14 tc" @click="mysellPop = true">MY SELLING</a>
                                </div>
                            </div>
                        </div>
                        <!--
                        <div class="info_box flex-box mt50" v-if="user">
                            <div class="flex-box info">
                                <img :src="user.avatar ? user.avatar : require('../assets/static/icon/avatar.png')"
                                    class="avatar" alt="" />

                                <div class="pl40 ">
                                    <div class="name flex-box fs24">{{ user.nickname }}</div>
                                    <div class="id fs18 pt20">ID: {{ user.idnum }}</div>
                                </div>
                            </div>
                            <div class="inventory flex-grow-1">
                                <div class="fs14 col5 pb15">YOUR INVENTORY</div>
                                <div class="flex-box flex-between">
                                    <div class="point_item">
                                        <span class="fs18">{{ user.coins | numberToCurrency }}</span>
                                        M-Coins
                                    </div>
                                    <div class="point_item">
                                        <span class="fs18">{{ user.rp | numberToCurrency }}</span>
                                        RP
                                    </div>
                                    <div class="point_item">
                                        <span class="fs18">{{ user.pp | numberToCurrency }}</span>
                                        PP
                                    </div>
                                    <div class="point_item">
                                        <span class="fs18">{{ user.ap | numberToCurrency }}</span>
                                        AP
                                    </div>
                                    <div class="point_item">
                                        <span class="fs18">{{ user.ep | numberToCurrency }}</span>
                                        EP
                                    </div>
                                </div>
                            </div>
                        </div>
                        -->
                        <div class="robots pt40 plr40 pb10 mt50">
                            <div class="flex-box flex-between pb30">
                                <div class="fs30 fwb col10">SELLING M-Coins STATUS</div>
                                <div class="fs14 col5">less than a minute ago</div>
                            </div>
                            <div class="flex-box flex-wrap">
                                <a href="javascript:;" @click="choosemore(index)" class="flex-box flex-between more_box"
                                    v-for="(item,index) in totallist2">
                                    <!-- <span class="fs16 col10">40</span>
                                    <span class="fs14 blue">More+</span> -->
                                    <span class="fs16 col10">{{item.price}}</span>
                                    <span class="fs14 blue">More+</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
        <popup :visible="topPop" :confirmText="false" :cancelText="false" @close="topPop = false">
            <div class="top_box">
                <div class="flex-box flex-between pb30">
                    <div class="flex-box title">
                        <div class="fs24 col10 fwb pr10">TOP 100 SELLING</div>
                        <div class="fs14 col89">(UPDATED EVERY MINUTE)</div>
                    </div>
                    <a href="javascript:;" class="close" @click="topPop = false"></a>
                </div>
                <div class="scrolls">
                    <div class="list">
                        <div class="title_box flex-box fs16 col3">
                            <div class="title_item">Date</div>
                            <div class="title_item flex-grow-1">Player ID</div>
                            <div class="title_item flex-grow-1">RP Per Unit</div>
                            <div class="title_item flex-grow-1">M-Coins</div>
                        </div>
                        <div class="scroll fs16 col3">
                            <div class="list_box flex-box" v-for="item in totallist">
                                <div class="list_item single-line">{{item.createtime_text}}</div>
                                <div class="list_item flex-grow-1">{{item.user_id}}</div>
                                <div class="list_item flex-grow-1">{{item.price}}</div>
                                <div class="list_item flex-grow-1">{{item.hascoins}}</div>
                                <!-- <div class="list_item single-line">25 Jul 2022 00:26 UTC</div>
                                <div class="list_item flex-grow-1">7559012</div>
                                <div class="list_item flex-grow-1">45</div>
                                <div class="list_item flex-grow-1">4543</div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </popup>
        <popup :visible="mybuyPop" :confirmText="false" :cancelText="false" @close="mybuyPop = false">
            <div class="top_box">
                <div class="flex-box flex-between pb30">
                    <div class="flex-box title">
                        <div class="fs24 col10 fwb pr10">MY BUYING</div>
                        <div class="fs14 col89"></div>
                    </div>
                    <a href="javascript:;" class="close" @click="mybuyPop = false"></a>
                </div>
                <div class="scrolls">
                    <div class="list">
                        <div class="title_box flex-box fs16 col3">
                            <div class="title_item">Date</div>
                            <div class="title_item flex-grow-1">Player ID</div>
                            <div class="title_item flex-grow-1">RP Per Unit</div>
                            <div class="title_item flex-grow-1">M-Coins</div>
                        </div>
                        <div class="scroll fs16 col3">
                            <div class="list_box flex-box" v-for="item in buylist">
                                <!-- <div class="list_item single-line">25 Jul 2022 00:26 UTC</div>
                                <div class="list_item flex-grow-1">7559012</div>
                                <div class="list_item flex-grow-1">45</div>
                                <div class="list_item flex-grow-1">4543</div> -->
                                <div class="list_item single-line">{{item.trtime_text}}</div>
                                <div class="list_item flex-grow-1">{{item.user_id}}</div>
                                <div class="list_item flex-grow-1">{{item.price}}</div>
                                <div class="list_item flex-grow-1">{{item.coins}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </popup>
        <popup :visible="mysellPop" :confirmText="false" :cancelText="false" @close="mysellPop = false">
            <div class="top_box">
                <div class="flex-box flex-between pb30">
                    <div class="flex-box title">
                        <div class="fs24 col10 fwb pr10">MY SELLING</div>
                        <div class="fs14 col89"></div>
                    </div>
                    <a href="javascript:;" class="close" @click="mysellPop = false"></a>
                </div>
                <div class="scrolls">
                    <div class="list">
                        <div class="title_box flex-box fs16 col3">
                            <div class="title_item">Date</div>
                            <div class="title_item flex-grow-1">Player ID</div>
                            <div class="title_item flex-grow-1">RP Per Unit</div>
                            <div class="title_item flex-grow-1">M-Coins</div>
                        </div>
                        <div class="scroll fs16 col3">
                            <div class="list_box flex-box" v-for="item in selllist">
                                <!-- <div class="list_item single-line">25 Jul 2022 00:26 UTC</div>
                                <div class="list_item flex-grow-1">7559012</div>
                                <div class="list_item flex-grow-1">45</div>
                                <div class="list_item flex-grow-1">4543</div> -->
                                <div class="list_item single-line">{{item.createtime_text}}</div>
                                <div class="list_item flex-grow-1">{{item.user_id}}</div>
                                <div class="list_item flex-grow-1">{{item.price}}</div>
                                <div class="list_item flex-grow-1">{{item.coins}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </popup>
        <popup :visible="Pop" :confirmText="false" :cancelText="false" @close="Pop = false">
            <div class="top_box">
                <div class="flex-box flex-between pb30">
                    <div class="flex-box title"><div class="fs24 col10 fwb pr10">TOP 100 SELLING</div> <div class="fs14 col89">(UPDATED EVERY MINUTE)</div></div>
                    <a href="javascript:;" class="close" @click="Pop = false"></a>
                </div>
                <div class="scrolls">
                    <div class="list">
                        <div class="title_box flex-box fs16 col3">
                            <div class="title_item">Date</div>
                            <div class="title_item flex-grow-1">Player ID</div>
                            <div class="title_item flex-grow-1">RP Per Unit</div>
                            <div class="title_item flex-grow-1">M-Coins</div>
                        </div>
                        <div class="scroll fs16 col3" v-if="totallist2.length>0">
                            <div class="list_box flex-box" v-for="item in totallist2[index].list">
                                <!-- <div class="list_item single-line">{{item.createtime_text}}</div>
                                <div class="list_item flex-grow-1">{{item.user_id}}</div>
                                <div class="list_item flex-grow-1">{{item.price}}</div>
                                <div class="list_item flex-grow-1">{{item.coins}}</div> -->
                                <div class="list_item single-line">{{item.createtime_text}}</div>
                                <div class="list_item flex-grow-1">{{item.user_id}}</div>
                                <div class="list_item flex-grow-1">{{item.price}}</div>
                                <div class="list_item flex-grow-1">{{item.hascoins}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </popup>
    </div>
</template>
<script>
import { MessageBox } from "element-ui";
import cookie from 'vue-cookies'
import { numberToCurrency } from "../assets/filters";
export default {
    filters: {
        numberToCurrency
    },
    data() {
        return {
            price: '',
            num: '',
            price2: '',
            num2: '',
            topPop: false,
            mybuyPop:false,
            mysellPop:false,
            Pop:false,
            user:'',
            configs:'',
            selllist:[],
            buylist:[],
            totallist:[],
            totallist2:[],
            index:0,
            option_column: {
                backgroundColor: '',
                grid: {
                    top: '5%',
                    left: '0%',
                    right: '5%',
                    bottom: '0%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis',
                    borderWidth: 1,
                    axisPointer: {
                        type: 'shadow'
                    },
                },
                xAxis: {
                    boundaryGap:false,
                    type: 'category',
                    data: [],
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#000' //X轴文字颜色
                        }
                    }
                },
                yAxis: [
                    {
                        type: 'value',
                        min: 0,
                        max: 400000,
                        interval:40000,
                        axisLabel: {
                            formatter(value) {
                                return `${value}`; // 保留一位小数
                            },
                            textStyle:{
                                color:'#000'
                            }
                        }
                    }
                ],
                series: [
                    {
                        type: 'line',
                        showAllSymbol: false, //显示所有图形。
                        //标记的图形为实心圆
                        symbolSize: 0, //标记的大小
                        lineStyle: {
                            color: '#1890FF'
                        },
                        data: []
                    },
                ]
            }

        };
    },
    created() {
        this.getuser()
        this.config()
        this.gettotallist()
    },

    methods: {
        
        config() {
            this.$api.config({}).then((res) => {
                if (res.code == 1) {
                    console.log(88)
                    this.configs = res.data
                    this.price2 = res.data.minprice
                    if (res.data.minprice) {
                        this.price = res.data.minprice

                    }
                    this.option_column.xAxis.data=res.data.data[0]
                    this.option_column.series[0].data=res.data.data[1]
                } else {
                    // that.$message.error(res.msg);
                    this.$message({
						message: res.msg,
						type: 'error',
						offset: window.screen.height / 2
					})
                }
            })
        },
        getuser() {
            if (cookie.get('sma_token')) {
                this.$api.apiUserInfo({}).then(ret => {
                    if (ret.code == 1) {

                        this.user = ret.data.userinfo;
                        this.getselllist()
                        this.getbuyinglist()
                    }
                })
            }


        },
        gettotallist() {
            this.$api.apiTotallist({}).then(ret => {
                if (ret.code == 1) {
                    console.log(99)
                    this.totallist=ret.data.list
console.log(ret.data.list2)
                    this.totallist2=ret.data.list2
                    console.log(this.totallist2)
                    console.log(this.totallist)
                }
            })
        },
        getselllist() {
            this.$api.apiSelllist({}).then(ret => {
                if (ret.code == 1) {
                    console.log(99)
                    this.selllist = ret.data
                    console.log(this.selllist)
                }
            })
        },
        getbuyinglist() {
            this.$api.apiBuylist({}).then(ret => {
                if (ret.code == 1) {
                    this.buylist = ret.data
                }
            })
        },
        confirmbuy() {
            if (cookie.get('sma_token')) {
                if (!this.price) {
                    // return this.$message.error('请输入价格')
                    return this.$message({
						message: "请输入价格",
						type: 'error',
						offset: window.screen.height / 2
					})
                }
                if (!this.num) {
                    // return this.$message.error('请输入数量')
                    return this.$message({
						message: "请输入数量",
						type: 'error',
						offset: window.screen.height / 2
					})
                }
                var data={num:this.num,price:this.price}
                var numReg = /^\+?[1-9][0-9]*$/ // 正整数

                var numRe = new RegExp(numReg)

                if (!numRe.test(this.num)) {

                    this.$message({

                        type: 'warning',

                        message: '请输入整数 ',

                        duration: 10000,

                        showClose: true,

                    })

                    return false

                }
                // (填写数量"+this.num+"为100的倍数，实际为"+this.num*100+"')
                MessageBox.confirm("确定要买入?", "提示", { type: "warning" })
                    .then((res) => {
                    // 判断 是否是 确认
                    console.log(res)
                    if (res == "confirm") {
                        console.log(888)
                        this.$api.apiCoinsbuy(data).then((res) => {
                            if(res.code==1){

                            //    this.$message.success(res.msg)
                                this.$message({
                                    message:res.msg,
                                    type: 'success',
                                    offset: window.screen.height / 2
                                })
                                window.location.reload(true)                 

                            }else{
                                // this.$message.error(res.msg)
                                this.$message({
                                    message: res.msg,
                                    type: 'error',
                                    offset: window.screen.height / 2
                                })
                            }


                            // that.$router.go(0);

                        });
                    }
				})
            }
           
            else {
                // this.$message.error('请先登录')
                this.$message({
                    message: '请先登录',
                    type: 'error',
                    offset: window.screen.height / 2
                })
            }
        },
        confirmsell() {
            if (cookie.get('sma_token')) {
                if (!this.price2) {
                    // return this.$message.error('请输入价格')
                    return this.$message({
						message: "请输入价格",
						type: 'error',
						offset: window.screen.height / 2
					})
                }
                if (!this.num2) {
                    // return this.$message.error('请输入数量')
                    return this.$message({
						message: "请输入数量",
						type: 'error',
						offset: window.screen.height / 2
					})
                }
                var data = { num: this.num2, price: this.price2 }
                var numReg = /^\+?[1-9][0-9]*$/ // 正整数

                var numRe = new RegExp(numReg)

                if (!numRe.test(this.num2)) {

                    this.$message({

                        type: 'warning',

                        message: '请输入整数 ',

                        duration: 10000,

                        showClose: true,

                    })

                    return false

                }
                // (填写数量" + this.num2 + "为100的倍数，实际为" + this.num2 * 100 + "')
                MessageBox.confirm("确定要卖出嘛?", "提示", { type: "warning" })
                    .then((res) => {
                    // 判断 是否是 确认
                    console.log(res)
                    if (res == "confirm") {
                        console.log(888)
                        this.$api.apiCoinssell(data).then((res) => {
                            if(res.code==1){                                 
                                // this.$message.success(res.msg)
                                this.$message({
                                    message:res.msg,
                                    type: 'success',
                                    offset: window.screen.height / 2
                                })
                                window.location.reload(true)
                            }else {
                                // this.$message.error(res.msg)
                                this.$message({
                                    message: res.msg,
                                    type: 'error',
                                    offset: window.screen.height / 2
                                })
                            }

                        })

                   
                
                    } 
                })
                
            }
        },
        choosemore(index){
            this.index=index
            this.Pop=true
        }
    },
};
</script>
